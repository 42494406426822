<template>
    <div class="m-about">
        <detail-nav :tabList="tabList" :curSite="curSite" @changeTab="changeTab" ref="detailNav"></detail-nav>
        <div class="m-content">
            <!--  info  -->
            <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10"
                 infinite-scroll-immediate-check="false">
                <ul class="m-news-info" v-if="isNav!='246'">
                    <li v-for="(item,index) in newData" @click="toDetail(item)" :key="index"><!-- newsList -->
                        <div class="m-left-img">
                            <img :src="item.titleImage" alt="">
                        </div>
                        <div class="m-right-content">
                            <div class="m-right-title">
                                <h3>{{item.newsTitle}}</h3><!-- {{item.newsTitle}} -->
                            </div>
                            <p>{{item.year}}-{{item.monthAndDay}}</p><!-- {{item.infoStartTime}} -->
                        </div>
                    </li>
                </ul>
                <!--  video  -->
                <p v-if="noMore" class="null-data">{{nullText}}</p>
            </div>

        </div>
    </div>
</template>

<script>
    import detailNav from "@/components/mobile/detailNav.vue";

    export default {
        name: "mobile_informationList",
        components: {
            detailNav
        },
        data() {
            return {
                curSite: {
                    name: "新闻资讯",
                    nameEn: 'News and Information',
                    path: 'news'
                },
                limit: 4,  //每页数据条数
                current: 1,   // 当前的页数
                tabList: [],
                isNav: 0, //246视频
                navName: "",
                navNameEn: "",
                newData: [],
                busy: false,
                noMore: true,
                nullText: "加载中...",
                count: 22,
            }
        },
        watch: {
            $route(to) {
                if (to.path == '/mobile/information') {
                    this.busy = false
                    this.current = 1
                    this.getData()
                }
            }
        },
        created() {
            this.getDataList();
			if (!/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/information",
						query:{
							typeId:this.$route.query.typeId
						}
			        });
			}
        },
        methods: {
            // 详情跳转
            toDetail(item) {
                this.$router.push({path: '/mobile/information/detail', query: {id: item.newsId}})
            },
            getDataList() {
                let _this = this
                new Promise(function (resolve) {
                    _this.$http.newsTypeList().then(res => {
                        if (res.code == 200) {
                            _this.tabList = res.data
                            resolve();
                        }
                    })
                }).then(function () {
                    _this.$http.newsList({page: 1, typeId: _this.$route.query.typeId}).then(res => {
                        if (res.code == 200) {
                            res.data.records.forEach(function (item) {
                                item.monthAndDay = item.monthAndDay.slice(0, 2) + '-' + item.monthAndDay.slice(4, 6)
                            })
                            _this.newData = res.data.records
                        }
                    })
                })
            },
            getData() {
                let _this = this
                _this.$http.newsList({page: 1, typeId: _this.$route.query.typeId}).then(res => {
                    if (res.code == 200) {
                        res.data.records.forEach(function (item) {
                            item.monthAndDay = item.monthAndDay.slice(0, 2) + '-' + item.monthAndDay.slice(4, 6)
                        })
                        _this.newData = res.data.records
                    }
                })
            },
            //滚动数据获取
            getProjectList(flag) {
                let params = {};
                params.typeId = this.$route.query.typeId;
                params.page = this.current;
                params.limit = this.limit;
                this.nullText = '加载中...';
                this.$http.newsList(params).then(res => {
                    if (res.code == 200) {
                        res.data.records.forEach(function (item) {
                            item.monthAndDay = item.monthAndDay.slice(0, 2) + '-' + item.monthAndDay.slice(4, 6)
                        })
                        if (flag) {//如果flag为true则表示分页
                            this.newData = this.newData.concat(res.data.records);  //concat数组串联进行合并
                            if (res.data.records.length == 0) {  //如果数据加载完 那么禁用滚动时间 this.busy设置为true
                                this.busy = true;
                                this.noMore = true;
                                this.nullText = '没有更多数据了';
                            } else {
                                this.busy = false;
                                this.noMore = false;
                            }
                        } else {//第一次进入页面 完全不需要数据拼接的
                            this.charityList = res.data.items;
                            this.busy = false;
                            this.nullText = '暂无数据';
                        }
                        this.loading = false;
                    }
                })
            },
            loadMore() {
                this.busy = true;
                this.noMore = true;
                this.nullText = '加载中...'
                setTimeout(() => {
                    this.current++;  //滚动之后加载第二页
                    this.getProjectList(true);
                }, 500);
            },
            changeTab(item) {
                const typeId = item.typeId
                const url = this.$route.path;
                this.$router.push({path: url, query: {typeId: typeId}})
            }
        }
    }
</script>

<style scoped>
    .m-videos-info {
        overflow: hidden;
    }

    .m-videos-info li {
        width: 21rem;
        margin: 0 auto 1rem;
        overflow: hidden;
    }

    /*630x354 = 1.78 */
    .m-video-img {
        width: 100%;
        height: 11.8rem;
        border-radius: .2rem;
        position: relative;
        overflow: hidden;
    }


    .m-news-info {
        overflow: hidden;
    }

    .m-news-info li {
        margin-bottom: 2rem;
        overflow: hidden;
        cursor: pointer;
    }

    .m-news-info li:last-child {
        border-bottom: none;
    }

    .m-left-img {
        float: left;
        margin-right: 1rem;
        width: 7.458rem;
        height: 5.082rem;
        border-radius: .264rem;
        overflow: hidden;
    }

    .m-left-img:hover img {
        transform: scale(1.2);
    }

    .m-left-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .5s ease-in-out;
        -webkit-transition: all .5s ease-in-out;
    }

    .m-right-content {

    }

    .m-right-title {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .67rem;
    }

    .m-right-title h3 {
        font-weight: normal;
        overflow: hidden;
        width: 100%;
        height: 3.1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #17191a;
        font-size: 1.122rem;
        line-height: 1.55rem;
    }

    .m-right-content p {
        margin-bottom: 0;
        line-height: 1.3;
        color: #879399;
        font-size: .792rem;
    }
</style>
